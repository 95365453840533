.dropZone {
  position: relative;
  display: flex !important;
  min-height: 70px;
  color: #adb5bd !important;
  border-color: currentColor !important;
  border-style: dashed !important;
  background-color: #fafbfe !important;
  cursor: pointer;
  &:hover {
    border-style: solid !important;
  }
  &:active {
    opacity: 0.7 !important;
  }
}

.dropZoneActive {
  border-color: #727cf5 !important;
}

.placeholder {
  text-align: center;
  margin: auto;
}
