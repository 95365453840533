//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-gutter: stable;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

// overrides
$header_height: 50px;
$logo_height: 16px;

.topnav-navbar {
  min-height: $header_height !important;
  height: $header_height !important;

  .topnav-logo {
    padding: 16px 26px !important;
    line-height: $logo_height !important;
  }
}

.page-title-box .page-title {
  font-size: 24px !important;
}

.container-fluid {
  max-width: 1900px !important;
}

.card:not(.shadow-none) {
  box-shadow: 0px 2px 6px 0px rgba(190, 193, 228, 0.351) !important;
}

.card-img-top {
  width: auto !important;
  max-width: 100%;
}

.footer {
  border-top: 0 !important;
}

// label
.form-label {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 6px;
}

.form-label:empty {
  display: none;
}

.textarea textarea {
  min-height: 75px;
}

.form-check-label {
  color: black;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-left: 8px;
}

// pleceholders
.dnd-pleceholder {
  min-height: 70px;
  color: #adb5bd;
  border: 1px dashed currentColor;
  border-radius: 0.25rem;
  background-color: #fafbfe;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

// helpers
.max-w-75-percent {
  max-width: 75%;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-300 {
  max-width: 300px;
}

.min-w-500 {
  min-width: 500px;
}
.min-w-300 {
  min-width: 300px;
}
.min-w-200 {
  min-width: 200px;
}
.min-w-100 {
  min-width: 130px;
}
.min-w-50 {
  min-width: 50px;
}

.icon {
  max-width: 18px;
  max-height: 18px;
}

.icon-button {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: scale(0.95);
  }
}

// grids
.responsive-grid {
  min-width: 320px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 46%);
    gap: 0 8%;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    gap: 0 2%;
  }

  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    gap: 0 1.333%;
  }
}
