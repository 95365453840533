.container {
  margin-bottom: 0;
  padding-top: 10px;
  min-height: 416px;
  box-shadow: none;
  cursor: pointer;
  transition: border-color 0.3s;
  border: 1px solid #d7d7d7;
  &:hover {
    border-color: #333;
  }
}

$imageMaxSize: 230px;

.cardImgBlock {
  width: 100%;
  height: $imageMaxSize;
  display: flex;
}

.imgPlaceholder {
  width: $imageMaxSize;
  height: $imageMaxSize;
  display: flex;
  margin: auto;
}

.img {
  display: block;
  margin: auto !important;
  max-width: $imageMaxSize;
  max-height: $imageMaxSize;
  border-radius: 0;
}
